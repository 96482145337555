















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MarketsService, { MarketsServiceS } from '../markets.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly dataType = DATA_TYPE.MARKETS;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.marketsService.isLoading) {
            return filters;
        }

        if (this.compsetsService.currentCompset) {
            const options = this.compsetsService.compsets!
                .map(compset => ({
                    name: compset.name,
                    value: compset.id,
                }));
            const value = this.compsetsService.currentCompset.id;

            filters.push({
                name: 'compset',
                label: 'Comp Set',
                value,
                options,
                disableOnEdit: true,
            });
        }

        if (this.marketsService.settings.pos) {
            filters.push({
                name: 'pos',
                label: 'POS',
                value: this.marketsService.settings.pos,
                options: this.documentFiltersService.posMarketItems,
                disableOnEdit: false,
            });
        }

        if (this.marketsService.settings.provider) {
            filters.push({
                name: 'providers',
                label: 'Source',
                value: this.marketsService.providers[0],
                options: this.marketsService.providers.map(p => ({
                    name: this.providersService.getProviderLabel(p),
                    value: p,
                })),
                disableOnEdit: false,
            });
        }

        filters.push({
            name: 'los',
            label: 'LOS',
            value: this.marketsService.settings.los,
            options: DEFAULT_LOS.map(l => ({
                name: String(l),
                value: l,
            })),
            disableOnEdit: false,
        });

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
